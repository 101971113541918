import jsonPlaceholder from '../api/jsonPlaceholder';
import _ from 'lodash';

export const fetchPostsAndUser = () => async (dispatch,getState) => {
    await dispatch (fetchPosts());

    // const userIds=_.uniq(_.map(getState().posts,'userId'));
    // console.log(getState());
    // userIds.forEach(id=>dispatch(fetchUser(id)));
    // console.log(userIds);
    _.chain(getState().posts)
    .map('userId')
    .uniq()
    .forEach(id=>dispatch(fetchUser(id)))
    .value();
};

export const fetchPosts=() => async dispatch =>  {
    const response= await jsonPlaceholder.get('/posts');

    dispatch ({type:'FETCH_POSTS', payload:response.data });
} ;

export const fetchUser= id=> async dispatch=>{
    const response= await jsonPlaceholder.get('/users/'+id);

    dispatch({type:'FETCH_USER',payload:response.data});
};

// **memoliz version**

// export const fetchUser= id=> dispatch=>{
//     _fetchUser (id, dispatch)
// };
// const _fetchUser= _.memoize(async(id,dispatch)=>{
//     const response= await jsonPlaceholder.get('/users/'+id);

//     dispatch({type:'FETCH_USER',payload:response.data});
// });