import React from 'react';
import {connect} from 'react-redux';
class UserHeader extends React.Component{
  render(){
      const {user}= this.props;
        if (!user){return <div className="spinner-grow text-success spinner-grow-sm"></div>}
      return <div><author className="badge badge-success">{user.name}</author></div>
  }
}
const mapStateToProps=(state,ownProps)=>{
    return { user:state.users.find(user=>user.id===ownProps.userId)}
};
export default connect(mapStateToProps) (UserHeader);