import React from 'react';
import {connect} from 'react-redux';
import{fetchPostsAndUser} from '../actions';
import UserHeader from './UserHeader';


 class PostList extends React.Component {
     componentDidMount(){
         this.props.fetchPostsAndUser();
     }
     renderList(){
        return this.props.posts.map(post=>{
            return(
            <div className="col-md-4 p-2 animated  fadeInUp ">
            <div className="card border-light m-1" key={post.id}>
                <div className="card-header">{post.title}</div>
                 <div className="card-body">
                     <p className="card-text">{post.body}</p>
                 <UserHeader userId={post.userId}></UserHeader>
                 </div>
                 </div>
            </div>)
        })
     };
     render() { console.log(this.props.posts)
         return ( <div className="row flex-row-reverse text-right justify-content-start">
                    {this.renderList()}
         </div> );
     }
 }
  const mapStateToProps= state=>{
      return {posts:state.posts};
  }
 export default connect(
    mapStateToProps,{fetchPostsAndUser})(PostList)
 ;